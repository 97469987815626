import React from "react";

const About = () => {
  return (
    <>
      <div>
        <p>This is about</p>
      </div>
    </>
  );
};

export default About;
